<template>
  <div>
    <div  :id="eid"></div>
    <y_upload_img_choose :show-input="false" :full="true" ref="imgChoose"></y_upload_img_choose>
  </div>
</template>

<script>
import E from 'wangeditor';
import y_upload_img_choose from "@/components/y-form/y-upload/y_upload_img";
export default {
  name: "y_editor",
  components:{
    y_upload_img_choose,
  },
  model:{
    prop:"modelval",
    event:'change'
  },
  props:{
    modelval: {
      type:String,
      default:""
    },
    eid:{
      type:String,
      default:"editor"
    },
    zi:{
      type:Number,
      default: 1,
    }
  },
  watch:{
    modelval(){
      let set = false;
      if(this.value == ""){
        set = true;
      }
      this.value = this.modelval;
      set ?  this.setContent() : "";
    },
    value(e){
      this.$emit('change',e);
    }
  },
  data(){
    return{
      editor:null,
      value: this.modelval,
    }
  },
  mounted() {
    this.init();
  },
  methods:{
    init(){
      this.editor = new E(`#${this.eid}`);
      this.editor.config.excludeMenus = [
        'emoticon','video','todo','code','link','fontName'
      ]
      this.editor.config.showFullScreen = false;
      this.editor.config.focus = false;
      this.editor.config.zIndex = this.zi;
      this.editor.config.onchange = (newHtml)=>{
        this.value = newHtml;
      };
      this.editor.config.showLinkImg = false;
      this.editor.config.uploadImgFromMedia =()=>{
        this.$refs.imgChoose.showUpload((e)=>{
          this.editor.cmd.do( 'insertHTML', `<img src="${e}" style="max-width:100%;"/>` )
        });
      }
      this.editor.config.onchangeTimeout = 500;
      this.editor.create();
      this.editor.txt.html(this.value);
    },
    setContent(){
      this.editor.txt.html(this.value)
    }
  },
  beforeDestroy() {
    this.editor.destroy()
    this.editor = null
  }
}
</script>

<style scoped>

</style>