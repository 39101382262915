import { render, staticRenderFns } from "./y_editor.vue?vue&type=template&id=f572d23c&scoped=true"
import script from "./y_editor.vue?vue&type=script&lang=js"
export * from "./y_editor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f572d23c",
  null
  
)

export default component.exports